<template>
  <v-container fluid>
    <industry-title :title="options.title.main" class="mb-2" />
    <industry-category :tabs="options.category" @select-tab="selectCategory" />
    <industry-organization :facilityType="facilityType" />
  </v-container>
</template>

<script >
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { PRODUCTION_FACILITY_TYPES } from "@/util/globals";
import { ORGANIZATION_TYPES } from "@/util/globals"; 

export default Vue.extend({
  name: "IndustryItems",
  components: {
    IndustryTitle: () =>
      import("@/components/reference/industry/IndustryTitle.vue"),
    IndustryCategory: () =>
      import("@/components/reference/industry/IndustryCategory.vue"),
    IndustryOrganization: () =>
      import("@/components/reference/industry/IndustryOrganization.vue"),
  },
  computed: {
    options: get("reference/optionsIndustry"),
  },
  data() {
    return {
      facilityType: PRODUCTION_FACILITY_TYPES.OilProducingCompany,
      currentOrganizationType: ORGANIZATION_TYPES.Produce,
    };
  },
  methods: {
    selectCategory(selectCategory) {
      this.facilityType = selectCategory.id;
      // this.currentOrganizationType = selectCategory;
      console.log('facility - ', this.currentOrganizationType);
    },
  },
  mounted() {},
});
</script>